import React, { useState, useEffect } from 'react'
import { frontApiUrl, clientId, clientSecret } from '../utility/config'
import { FrontComponent } from './Front'
import { FrontPayload, TransferFinishedPayload } from '@front-finance/link'
import { BrokerType, FrontApi } from '@front-finance/api'
import { BrowserRouter, useSearchParams } from 'react-router-dom'

const api = new FrontApi({
  baseURL: frontApiUrl,
  headers: {
    'x-client-id': clientId, // insert your client id here
    'x-client-secret': clientSecret // do not use your clientSecret on the FE
  }
})

export const App: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [payload, setPayload] = useState<FrontPayload | null>(null)

  const userId = searchParams.get('userId') || Math.random().toString();
  const brokerType: BrokerType | undefined = searchParams.get('brokerType') as BrokerType || undefined;

  useEffect(() => {
    async function doIt() {
      setError(null)
      setLinkToken(null)

      // this request should be performed from the backend side
      const response = await api.managedAccountAuthentication.v1LinktokenCreate({
        userId, // insert your unique user identifier here
        brokerType,
        restrictMultipleAccounts: true
      })

      const data = response.data
      if (response.status !== 200 || !data?.content) {
        const error = (data && data.message) || response.statusText
        console.error('Error!', error)
        setError(error)
      } else if (!data.content.linkToken) {
        setError('Iframe url is empty')
      } else {
        setLinkToken(data.content.linkToken)
      }
    }

    doIt();
  }, [])

  return (
    <div style={{ padding: '15px' }}>
      {(payload && (
        <div style={{ wordWrap: 'break-word' }}>
          <h1>Connected!</h1>
          <p>
            <b>Broker:</b> {payload.accessToken?.brokerName}
            <br />
            <b>Token:</b> {payload.accessToken?.accountTokens[0].accessToken}
            <br />
            <b>Refresh Token:</b>{' '}
            {payload.accessToken?.accountTokens[0].refreshToken}
            <br />
            <b>Token expires in seconds:</b>{' '}
            {payload.accessToken?.expiresInSeconds}
            <br />
            <b>ID:</b> {payload.accessToken?.accountTokens[0].account.accountId}
            <br />
            <b>Name: </b>
            {payload.accessToken?.accountTokens[0].account.accountName}
            <br />
            <b>Cash:</b> ${payload.accessToken?.accountTokens[0].account.cash}
            <br />
          </p>
        </div>
      )) || (
        <p>
          Loading MeshLink ...
        </p>
      )}

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      <FrontComponent
        linkToken={linkToken}
        onBrokerConnected={async (authData: FrontPayload) => {
          setPayload(authData)
          setLinkToken(null)

          if(authData.accessToken) {
            // query all holdings for all account tokens
            await Promise.all(authData.accessToken.accountTokens.map(async token => {
              const holdings = await api.portfolio.v1HoldingsGetCreate({
                type: authData.accessToken?.brokerType as BrokerType, authToken: token.accessToken});
              console.log(holdings);
            }));

            // notify Flutter about successful broker connection
            (window as any).ResultInvoker?.postMessage('success');
          }
        }}
        onExit={err => {
          setLinkToken(null)
          setError(err || null)
        }}
        onTransferFinished={data => {
          //setTransferFinishedData(data)
        }}
      />
    </div>
  )
}

export default App
